<template>
  <div :key="locale" class="sp-app-container">
    <PartnerHeader />
    <slot />
    <FooterSp :partner-page="true" />
  </div>
</template>

<script setup>
import PartnerHeader from '@/components/PartnerHeader/PartnerHeader'
import FooterSp from '@/components/Footer/Footer.vue'

const { locale } = useCurrentLocale()

const { defaultHead, defaultSeo } = useDefaultMetaData()
useHead(defaultHead())
useSeoMeta(defaultSeo())

onMounted(() => {
  nextTick(() => {
    if (!window.gtmLoaded) {
      window.gtmLoaded = true
      const gtm = useGtm()
      gtm.enable(true) // Enable GTM dynamically
    }
  })
})
</script>
