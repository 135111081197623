<template>
  <div>
    <div class="partner-header">
      <div class="inner-container full-width">
        <div class="partner-header__flex">
          <ImageLazy
            v-if="rawLanding?.logo?.filename"
            :src="rawLanding?.logo?.filename"
            :alt="rawLanding?.title"
            :title="rawLanding?.title"
          />
          <span>x</span>
          <NuxtLinkLocale :to="'index'">
            <img
              src="~/assets/img/svg/logos/logo-stockpro-color.svg"
              :alt="$t('header.logoAlt')"
              :title="$t('header.logoAlt')"
              class="logo logo-full"
            />
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLandingDownloadStore } from '@/stores/landingDownload'
export default {
  name: 'PartnerHeader',
  computed: {
    rawLanding() {
      const landingDownloadStore = useLandingDownloadStore()
      return landingDownloadStore.landingDownload || landingDownloadStore.landingDynamic
    },
  },
}
</script>

<style lang="scss">
.partner-header {
  background-color: var(--white);
  padding: 22px 0;

  @include mq($mq-sm) {
    padding: 5px 0;
  }

  img {
    display: block;

    &:first-child {
      max-height: 50px;
    }
  }

  span {
    color: var(--steel-grey);
    font-family: Avenir;
    font-size: pxToRem(20px);
    font-weight: 800;
  }

  &__flex {
    display: flex;
    align-items: center;

    @include mq($mq-md) {
      justify-content: center;
    }

    @include mq($mq-sm) {
      transform: scale(0.8);
    }

    & > * {
      &:not(:last-child) {
        margin-right: 20px;

        @include mq($mq-sm) {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
